
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
// import Multiselect from "@vueform/multiselect";
import ReportService from "@/core/services/car/ReportService";
import UserService from "@/core/services/UserService";
import MasterService from "@/core/services/car/MasterService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RecordSelect from "../components/recordSelect.vue";
import moment from "moment";
import { getMonthDate, roles } from '@/store/stateless/store';

interface IPayload {
  start_date: string;
  end_date: string;
  user_id: any;
  per_page: number | string;
  page: number;
}

export default defineComponent({
  // component name
  name: "report-skye-login-info",
  components: {
    RecordSelect
  },
  data() {
    // constants
    return {
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            //end.setDate(1);
            //end.setHours(-1);
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ]
    };
  },
  setup() {
    const store = useStore();
    const value2 = ref<Array<string>>([]);

    let bodyPayloads = ref<IPayload>({
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      user_id: [],
      per_page: 50,
      page: 1
    });
    let exportUrl = ref<string>("");

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    let usersList = computed(() => {
      return store.getters.getDomSkyeUsers;
    });

    // let usersList = ref({
    //   mode: "tags",
    //   data: [],
    //   options: users,
    //   placeholder: "Select User",
    //   searchable: true,
    //   createTag: true
    // });

    const defaultBodyPayloads = computed(() => {
      return store.getters.getCommonPayLoad;
    });
    const displayResponseData = computed(() => {
      return store.getters.getSkyeLoginInfoReports;
    });
    const myPagination = computed(() => {
      return store.getters.getExportPagination;
    });

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.page = currentPage.value;
      ReportService.callingSkyeLoginInfoReport(bodyPayloads.value);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.per_page = event.target.value;
      ReportService.callingSkyeLoginInfoReport(bodyPayloads.value);
    };

    const exportReport = () => {
      Promise.all([
        ReportService.exportingSkyeLoginInfoReport(bodyPayloads.value)
      ]).then((data) => {
        let exportResult = computed(() => {
          return store.getters.getExport;
        });
        exportUrl.value = '';
        const DownloadReportRoute = process.env.VUE_APP_API_URL + 'download-report/' + exportResult.value
        window.open(DownloadReportRoute)
      });
    };

    const searchByDate = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
      var endTS = new Date(listPeriod[1]);
      bodyPayloads.value.start_date = moment(startTS.getFullYear() + "-" + (startTS.getMonth() + 1) + "-" + startTS.getDate()).format("YYYY-MM-DD");
      bodyPayloads.value.end_date = moment(endTS.getFullYear() + "-" + (endTS.getMonth() + 1) + "-" + endTS.getDate()).format("YYYY-MM-DD");
      } else {
        bodyPayloads.value.start_date = '';
        bodyPayloads.value.end_date = '';
      }
      ReportService.callingSkyeLoginInfoReport(bodyPayloads.value);
    };

    const getDomSkyeUsers = () => {
      Promise.all([UserService.getDomSkyeUsers()]).then((data) => {
        usersList = computed(() => {
          return store.getters.getDomSkyeUsers;
        });
        // usersList.value = {
        //   mode: "tags",
        //   data: [],
        //   options: users,
        //   placeholder: "Select User",
        //   searchable: true,
        //   createTag: true
        // };
      });
    };

    const onUserChange = () => {
      // bodyPayloads.value.user_id = event;
      ReportService.callingSkyeLoginInfoReport(bodyPayloads.value);
    };

    const resetUser = () => {
      // bodyPayloads.value.user_id = event == null ? "" : event;
      ReportService.callingSkyeLoginInfoReport(bodyPayloads.value);
    };

    const resetAndRefreshFilter = () => {
      bodyPayloads.value = {
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
        user_id: [],
        per_page: 50,
        page: 1
      };
      value2.value = getMonthDate.currentMonth();
      ReportService.callingSkyeLoginInfoReport(bodyPayloads.value);
      getDomSkyeUsers();
      exportUrl.value = "";
    };

    onMounted(() => {
      if(!roles.reportLoginInfor.includes(roles.userId())) window.location.href = '/#/404'
      
      
      setCurrentPageBreadcrumbs("Skye Login Info", ["Reports"]);
      getDomSkyeUsers();
      ReportService.callingSkyeLoginInfoReport(defaultBodyPayloads.value);
      value2.value = getMonthDate.currentMonth();
    });

    const currentPage = ref(1);
    const loading = computed(() => {
        return store.getters.getARLoading;
    });
    return {
      loading,
      roles,
      value2,
      currentPage,
      defaultBodyPayloads,
      displayResponseData,
      myPagination,
      onPerPageChange,
      getPaginationUrl,
      resetAndRefreshFilter,
      exportUrl,
      exportReport,
      disableSaveButton,
      searchByDate,
      usersList,
      onUserChange,
      resetUser,
      bodyPayloads
    };
  },
  methods: {
    indexMethod(index) {
      return (
        this.myPagination.current_page * this.myPagination.per_page -
        this.myPagination.per_page +
        index +
        1
      );
    }
  }
});
